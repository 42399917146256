import { bindable, customElement, inject, Loader, useView } from 'aurelia-framework';
import { DistrictsRepository }                              from 'modules/administration/locations/districts/services/repository';
import { FormHelperService }                                from 'resources/elements/aurelia-form/components/form-helper-service';
import { FormSelect2 }                                      from 'resources/elements/aurelia-form/components/form-select2';
import { AppContainer }                                     from 'resources/services/app-container';

@inject(AppContainer, Loader, FormHelperService, DistrictsRepository)
@customElement('form-districts-select')
@useView('./form-select2.html')
export class FormDistrictsSelect extends FormSelect2 {

    @bindable selectedOption;

    /**
     * Constructor
     *
     * @param appContainer
     * @param loader
     * @param formHelperService
     * @param repository
     */
    constructor(appContainer, loader, formHelperService, repository) {
        super(appContainer, loader, formHelperService);

        this.repository = repository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return './form-select2.html';
    }

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        model.element.remoteSource   = this.repository.active.bind(this.repository);
        model.element.processResults = (row) => {
            row.name = `${row.code} - ${row.name}`;

            return row;
        };

        return super.activate(model);
    }

}
