import { inject, useView }       from 'aurelia-framework';
import { UsersRepository }       from 'modules/administration/users/services/repository';
import { FormHelperService }     from 'resources/elements/aurelia-form/components/form-helper-service';
import { FormMultiselectNative } from 'resources/elements/aurelia-form/components/form-multiselect-native';
import { AppContainer }          from 'resources/services/app-container';

@inject(AppContainer, FormHelperService, UsersRepository)
@useView('./form-multiselect-native.html')
export class FormUserMultiselect extends FormMultiselectNative {

    /**
     * Constructor
     *
     * @param appContainer
     * @param formHelperService
     * @param usersRepository
     */
    constructor(appContainer, formHelperService, usersRepository) {
        super(appContainer, formHelperService);

        this.usersRepository = usersRepository;
    }

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        model.element.remoteSource = this.usersRepository.active.bind(this.usersRepository);

        return super.activate(model);
    }

}
