import { bindable, customElement, inject } from 'aurelia-framework';
import { BaseComponent }                   from 'resources/elements/aurelia-form/components/base-component';
import { AppContainer }                    from 'resources/services/app-container';

@inject(AppContainer)
@customElement('form-field-group')
export class FormFieldGroup extends BaseComponent {
    @bindable fields = [];

    fieldGroupKey;

    /**
     * Constructor
     *
     * @param appContainer
     */
    constructor(appContainer) {
        super(appContainer);
    }

    activate(model) {
        this.fieldGroupKey = model.element.key;
        this.fields        = model.element.fields;

        super.activate(model);
    }
}
