import { inject, singleton } from 'aurelia-framework';
import { I18N }              from 'aurelia-i18n';

@singleton()
@inject(I18N)
export class FormHelperService {
    /**
     * Constructor
     *
     * @param i18n
     */
    constructor(i18n) {
        this.i18n = i18n;
    }

    /**
     * Checks whether the helper should be displayed
     *
     * @param element
     *
     * @returns boolean
     */
    displayHelper(element) {
        return element.helper && this.i18n.tr(element.helper) !== element.helper;
    }

    /**
     * Returns input group for the element field helper
     *
     * @param element
     *
     * @returns {{}}
     */
    inputGroup(element) {
        if (!this.displayHelper(element)) {
            return element.inputGroup;
        }

        // use the existing input group or define a new one
        let inputGroup = element.inputGroup || {
            position: 'right',
        };

        inputGroup.popover = {
            title:     'form.field.help',
            content:   element.helper,
            trigger:   'focus',
            placement: 'bottom',
            html:      true,
            icon:      { class: 'icon-question3' },
        };

        return inputGroup;
    }
}
