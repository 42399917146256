import { inject }       from 'aurelia-framework';
import { AppContainer } from 'resources/services/app-container';

@inject(AppContainer)
export class BaseComponent {

    observers      = [];
    eventListeners = [];

    /**
     * Constructor
     *ł
     * @param appContainer
     */
    constructor(appContainer) {
        this.appContainer = appContainer;
    }

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        this.model = model;

        let idPrefix        = ((this.model.element.idPrefix) ? this.model.element.idPrefix + '__' : '');
        this.modelElementId = (this.model.element.id || this.model.element.key);
        if (this.modelElementId) {
            this.modelElementId = this.modelElementId.replaceAll('.', '__');
            this.modelElementId = idPrefix + this.modelElementId;
        }

        if (typeof this.model.element.attributes === 'undefined') {
            this.model.element.attributes = {};
        }

        // this instance in order to be possible to access it from outside
        this.model.element.instance = this;

        return this.fetchData();
    }

    /**
     * Handles attached event
     */
    attached() {
        this.createElement();

        this.subscribeObservers();
        this.subscribeEventListeners();
        this.subscribeUserDefinedObservers();
        this.subscribeUserDefinedEventListeners();

        this.evaluateFloatingLabel(this.model.value);
    }

    /**
     * Handles detached event
     */
    detached() {
        this.destroyElement();
        this.disposeObservers();
        this.disposeEventListeners();
    }

    /**
     * Clears element value(s)
     */
    clear() {
        if (this.model.value instanceof Array) {
            this.model.value.splice(0, this.model.value.length);
        } else {
            this.model.value = null;
        }
    }

    /**
     * Checks whether the element is disabled
     *
     * @returns {boolean}
     */
    isDisabled() {
        return this.model.element.attributes.disabled === true;
    }

    /**
     * Checks whether the element is enabled
     *
     * @returns {boolean}
     */
    isEnabled() {
        return !this.isDisabled();
    }

    /**
     * Makes element readonly
     *
     * @param value
     * @return {*|Promise}
     */
    readonly(value = true) {
        this.model.element.attributes.readonly = value !== false;

        return Promise.resolve(true);
    }

    /**
     * Disables element
     */
    disable(value = true, clear = true) {
        if (value === false) {
            return this.enable();
        }

        if (value !== this.model.element.attributes.disabled) {
            this.model.element.attributes.disabled = true;

            if (clear) {
                this.clear();
            }
        }

        return Promise.resolve(true);
    }

    /**
     * Enables element
     */
    enable(value = true) {
        if (value === false) {
            return this.disable();
        }

        this.model.element.attributes.disabled = false;

        return Promise.resolve(true);
    }

    /**
     * Disables element
     */
    hide(value = true, clear = true) {
        if (value === false) {
            return this.show();
        }

        this.model.element.hidden = true;

        if (this.model.element.attributes) {
            this.model.element.attributes.hidden = true;
        }

        if (clear) {
            this.clear();
        }

        return Promise.resolve(true);
    }

    /**
     * Enables element
     */
    show(value = true) {
        if (value === false) {
            return this.hide();
        }

        this.model.element.hidden = false;

        if (this.model.element.attributes) {
            this.model.element.attributes.hidden = false;
        }

        return Promise.resolve(true);
    }

    /**
     * Toggles element visibility
     */
    toggleVisibility() {
        if (this.model.element.hidden === true) {
            return this.show();
        }

        return this.hide();
    }

    /**
     * Defines whether the element is required
     *
     * @param required
     */
    required(required = true) {
        this.model.element.required = required;

        return Promise.resolve(true);
    }

    /**
     * Fetches data from remote source
     *
     * @returns {Promise}
     */
    fetchData() {
        return this.simplePromise();
    }

    /**
     * Subscribes observers
     */
    subscribeObservers() {
        // subscribes `model.value` property change
        this.observers.push(
            this.appContainer
                .bindingEngine
                .propertyObserver(this.model, 'value')
                .subscribe(this.evaluateFloatingLabel.bind(this)),
        );
    }

    /**
     * Subscribes user defined observers
     */
    subscribeUserDefinedObservers() {
        if (this.model.element.observers instanceof Array) {
            this.model.element.observers.forEach((observer) => {
                if (observer instanceof Function) {
                    this.registerObserver(observer);
                }
            });
        }
    }

    /**
     * Subscribes event listeners
     */
    subscribeEventListeners() {
        // subscribes `locale-changed` event
        this.eventListeners.push(
            this.appContainer.eventAggregator.subscribe('locale-changed', () => {
                this.fetchData()
                    .then((response) => this.destroyElement())
                    .then((response) => setTimeout(() => this.createElement(), 0));
            }),
        );

        // subscribes `form-reseted` event
        this.eventListeners.push(
            this.appContainer.eventAggregator.subscribe('form-reseted', (formId) => {
                if (formId === this.model.formId) {
                    this.destroyElement()
                        .then((response) => setTimeout(() => this.createElement(), 0));
                }
            }),
        );
    }

    /**
     * Subscribes user defined event listeners
     */
    subscribeUserDefinedEventListeners() {
        if (this.model.element.eventListeners instanceof Array) {
            this.model.element.eventListeners.forEach((eventListener) => {
                this.eventListeners.push(
                    this.appContainer.eventAggregator.subscribe(eventListener.name, eventListener.operation),
                );
            });
        }
    }

    /**
     * Registers observers
     *
     * @param observer
     *
     * @returns {Number}
     */
    registerObserver(observer) {
        if (this.model.value instanceof Array) {
            return this.observers.push(
                this.appContainer
                    .bindingEngine
                    .collectionObserver(this.model.value)
                    .subscribe(observer),
            );
        }

        return this.observers.push(
            this.appContainer
                .bindingEngine
                .propertyObserver(this.model, 'value')
                .subscribe(observer),
        );
    }

    /**
     * Disposes event listeners
     */
    disposeEventListeners() {
        while (this.eventListeners.length) {
            this.eventListeners.pop().dispose();
        }

        this.eventListeners.length = 0;
    }

    /**
     * Disposes observers
     */
    disposeObservers() {
        while (this.observers.length) {
            this.observers.pop().dispose();
        }

        this.observers.length = 0;
    }

    /**
     * Creates element
     */
    createElement() {
        return this.simplePromise();
    }

    /**
     * Destroys element
     */
    destroyElement() {
        return this.simplePromise();
    }

    /**
     * Recreates element
     */
    recreateElement() {
        return this.destroyElement().then(() => setTimeout(() => this.createElement(), 0));
    }

    /**
     * Evaluates floating label
     *
     * @param value
     */
    evaluateFloatingLabel(value) {
        let label = $('#' + this.modelElementId).parents('.form-group-float').children('.form-group-float-label');

        // ignores the fixed label components
        if (!label.hasClass('fixed-label')) {
            value = (value !== null && typeof value !== 'undefined') ? value : '';

            // toggle label visibility
            if (value !== '') {
                label.addClass('is-visible');
            } else {
                label.removeClass('is-visible').addClass('animate');
            }
        }
    }

    /**
     * Returns a simple promise from a given callback
     *
     * @param callback
     *
     * @returns {Promise}
     */
    simplePromise(callback = () => {
    }) {
        return new Promise((resolve, reject) => {
                callback();

                resolve(true);
                reject(new Error('Error'));
            },
        );
    }

    highestZIndex() {
        return Array.from(document.querySelectorAll('body *'))
            .map(a => parseFloat(window.getComputedStyle(a).zIndex))
            .filter(a => !isNaN(a))
            .sort((a, b) => a - b)
            .pop();
    }

}
