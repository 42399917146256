import { bindable, inject }           from 'aurelia-framework';
import { AppContainer }               from 'resources/services/app-container';
import { BaseComponent }              from 'resources/elements/aurelia-form/components/base-component';
import { FormHelperService }          from 'resources/elements/aurelia-form/components/form-helper-service';
import { remove as removeDiacritics } from 'diacritics';

const KEYBOARD_KEY = {
    BACKSPACE: 8,
    TAB:       9,
    ENTER:     13,
    SHIFT:     16,
    CTRL:      17,
    ALT:       18,
    ESC:       27,
    SPACE:     32,
    PAGE_UP:   33,
    PAGE_DOWN: 34,
    END:       35,
    HOME:      36,
    LEFT:      37,
    UP:        38,
    RIGHT:     39,
    DOWN:      40,
    DELETE:    46,
};

@inject(AppContainer, FormHelperService)
export class FormMultiselectNative extends BaseComponent {

    @bindable allOptionsSelected = false;
    @bindable filterText         = '';

    filteredOptions = [];
    disabled        = false;
    expanded        = false;
    displayText     = '';

    model = {
        value: [],
    };

    outerContainer;
    filterInput;

    // indicates if the dropdown was opened at least once
    // avoid the bug related to virtual-repeats on initially hidden elements (related: https://github.com/aurelia/ui-virtualization/issues/73)
    firstDisplay = false;

    /**
     * Constructor
     *
     * @param appContainer
     * @param formHelperService
     */
    constructor(appContainer, formHelperService) {
        super(appContainer);

        this.formHelperService = formHelperService;
    }

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        model.element.inputGroup = this.formHelperService.inputGroup(model.element);

        this.model          = model;
        this.modelElementId = this.model.element.id || this.model.element.key;

        this.model.element.attributes = this.model.element.attributes || {};

        if (typeof this.model.value === 'undefined' || this.model.value === null) {
            this.model.value = [];
        }

        if (!(this.model.element.options && this.model.element.options instanceof Array)) {
            this.model.element.options = [];
        }

        this.filteredOptions.splice(0, this.filteredOptions.length, ...this.model.element.options);

        this.disabled = this.model.element.attributes.disabled === true;

        // this instance in order to be possible to access it from outside
        this.model.element.instance = this;

        return this.fetchData();
    }

    /**
     * Handles attached event
     */
    attached() {
        super.attached();

        this.resolveDisplayText();
    }

    /**
     * Fetches data from remote source
     *
     * @returns {*}
     */
    fetchData() {
        let parameters = {};

        if (this.model.element.remoteSourceParameters instanceof Function) {
            parameters = this.model.element.remoteSourceParameters();

            if (!parameters) {
                return Promise.resolve([]);
            }
        }

        return this.model.element
            .remoteSource(parameters)
            .then((response) => {
                if (this.model.element.processResults instanceof Function) {
                    response = response.map(item => this.model.element.processResults(item));
                }

                this.model.element.options.splice(0, this.model.element.options.length, ...response);
                this.filteredOptions.splice(0, this.filteredOptions.length, ...this.model.element.options);

                return response;
            });
    }

    /**
     * Recreates element
     *
     * @returns {Promise|Promise<number>}
     */
    recreateElement() {
        return this.simplePromise();
    }

    /**
     * Override to prevent firing of the base component's subscribeObservers() function
     */
    subscribeObservers() {
        // subscribes `model.value` property change
        this.observers.push(
            this.appContainer
                .bindingEngine
                .collectionObserver(this.model.value)
                .subscribe((splices) => this.onModelValueChanged(splices)),
        );

        this.observers.push(
            this.appContainer
                .bindingEngine
                .collectionObserver(this.model.element.options)
                .subscribe((splices) => this.onModelOptionsChanged(splices)),
        );

        // subscribes `settings.disabled` property change
        this.observers.push(
            this.appContainer
                .bindingEngine
                .propertyObserver(this.model.element.attributes, 'disabled')
                .subscribe((newValue, oldValue) => this.onDisabledChanged(newValue, oldValue)),
        );
    }

    /**
     * Subscribes event listeners
     */
    subscribeEventListeners() {
        super.subscribeEventListeners();

        // sadly, we have to use jQuery, as vanilla `addEventListener` does not catch bootstrap events...
        $(this.outerContainer).on('shown.bs.dropdown', () => this.onContainerExpanded());
    }

    /**
     * Handles change of `model value`
     */
    onModelValueChanged(splices) {
        this.resolveDisplayText();
    }

    /**
     * Handles change of `model options`
     */
    onModelOptionsChanged(splices) {
        this.resolveDisplayText();
    }

    /**
     * Handle change of `disabled` property
     */
    onDisabledChanged(newValue, oldValue) {
        this.disabled = !!newValue;
    }

    /**
     * Handles locale change
     *
     * @returns {Promise|Promise<number>}
     */
    onLocaleChanged() {
        return Promise.resolve(this.resolveDisplayText());
    }

    /**
     * Handles click inside container
     *
     * @param $event
     */
    onContainerClick($event) {
        $event.stopImmediatePropagation();

        return true;
    }

    /**
     * Handles container expand
     */
    onContainerExpanded() {
        if (this.model.element.options.length) {
            this.clearFilter();
            this.firstDisplay = true;
        }
    }

    /**
     * Handles form reset
     *
     * @param formId
     */
    onFormReseted(formId) {
        if (formId === this.model.formId) {
            this.resolveDisplayText();
        }
    }

    /**
     * Handles option keypress
     *
     * @param $event
     */
    onOptionKeypress($event) {
        if (!this.isDisabled()) {
            let key    = $event.which;
            let option = $event.target.querySelector('input');

            switch (key) {
                case KEYBOARD_KEY.UP:
                    this.highlightPreviousOption($event.target);
                    break;
                case KEYBOARD_KEY.DOWN:
                    this.highlightNextOption($event.target);
                    break;
                case KEYBOARD_KEY.ENTER:
                case KEYBOARD_KEY.SPACE:
                    $event.preventDefault();
                    this.selectOption(option);
                    break;
                default:
                    break;
            }
        }

        return true;
    }

    /**
     * Selects an option
     *
     * @param option
     */
    selectOption(option) {
        option.checked = !option.checked;

        option.dispatchEvent(new CustomEvent('change', { bubbles: false }));
    }

    /**
     * Highlights previous option
     *
     * @param currentOption
     */
    highlightPreviousOption(currentOption) {
        if (currentOption.previousElementSibling) {
            currentOption.previousElementSibling.focus();
        }
    }

    /**
     * Highlights next option
     *
     * @param currentOption
     */
    highlightNextOption(currentOption) {
        if (currentOption.nextElementSibling) {
            currentOption.nextElementSibling.focus();
        }
    }

    /**
     * Handles change of `search text`
     */
    filterTextChanged() {
        const filterText = removeDiacritics(this.filterText).toLowerCase();

        const filteredOptions = this.model.element.options.filter(option => removeDiacritics(option.name).toLowerCase().includes(filterText));

        this.filteredOptions.splice(0, this.filteredOptions.length, ...filteredOptions);
    }

    /**
     * Handles change of `all options selected`
     */
    allOptionsSelectedChanged() {
        this.allOptionsSelected ? this.selectAll() : this.deselectAll();
    }

    /**
     * Clears search field
     */
    clearFilter() {
        this.filterText = '';

        this.filterInput.focus();
    }

    /**
     * Selects all items
     */
    selectAll() {
        const allItems = this.model.element.options.map(item => item.id);

        this.model.value.splice(0, this.model.value.length, ...allItems);
    }

    /**
     * Deselects all items
     */
    deselectAll() {
        this.model.value.splice(0, this.model.value.length);
    }

    /**
     * Resolves display text
     */
    resolveDisplayText() {
        this.displayText = this.appContainer.i18n.tr('text.no-options-selected');

        if (this.model.value.length) {
            const selectedItems = this.model.element.options.filter(option => this.model.value.indexOf(option.id) !== -1);

            if (selectedItems.length < 4) {
                this.displayText = selectedItems.map(item => item.name).join(', ');
            } else {
                this.displayText = this.appContainer.i18n.tr('text.n-options-selected', {n: selectedItems.length});
            }
        }
    }

    /**
     * Returns selected items
     *
     * @returns {*}
     */
    selectedItems() {
        return this.model.element.options.filter(option => this.model.value.indexOf(option.id) !== -1);
    }

    /**
     * Returns an item by its id
     *
     * @returns {*}
     */
    item(id) {
        return this.model.element.options.find(option => option.id === id);
    }

}
