import { customElement, inject } from 'aurelia-framework';
import { AppContainer }          from 'resources/services/app-container';
import { BaseComponent }         from 'resources/elements/aurelia-form/components/base-component';

@inject(AppContainer)
@customElement('form-acbin-duallistbox')
export class FormAcbinDualListBox extends BaseComponent {

    get isDisabled() {
        let attributes = this.model.element.attributes;

        if (typeof attributes === 'undefined' || attributes === null) {
            return false;
        }

        return attributes.disabled;
    }

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        this.model          = model;
        this.modelElementId = this.model.element.id || this.model.element.key;

        this.customSettings = this.model.element.settings ? this.model.element.settings : {};

        // init some variables
        this.to_select_values   = [];
        this.to_deselect_values = [];

        this.selectable_elements   = [];
        this.deselectable_elements = [];

        this.selected_options = [];

        this.to_select_filter   = '';
        this.to_deselect_filter = '';

        // this instance in order to be possible to access it from outside
        this.model.element.instance = this;

        return this.fetchData();
    }

    /**
     * Fetches data from remote source
     *
     * @returns {*}
     * @returns {*}
     */
    fetchData() {
        let parameters = {};

        if (typeof this.model.element.remoteSourceParameters === 'function') {
            parameters = this.model.element.remoteSourceParameters();
        }

        return this.model.element.remoteSource(parameters)
            .then((response) => {
                this.backup_options = [];
                this.backup_options.splice(0, this.backup_options.length, ...response);

                return this.model.element.options = response;
            });
    }

    /**
     * Creates element
     */
    createElement() {
        return this.simplePromise(() => {
            // reset some variables
            this.refresh_to_select   = true;
            this.refresh_to_deselect = true;

            this.to_select_filter_count   = 0;
            this.to_deselect_filter_count = 0;

            this.selectable_elements.splice(0, this.selectable_elements.length, ...this.model.element.options);

            // restore selectable pool from backup options
            this.model.element.options.splice(0, this.model.element.options.length, ...this.backup_options);
            for (let a in this.model.element.options) {
                this.model.element.options[a].___show = true;
            }

            this.deselectable_elements = [];

            if (!this.model.value instanceof Array) {
                this.model.value = [];
            } else {
                this._selectByArray(
                    this.model.element.options,
                    this.model.value,
                    this.selected_options,
                );
            }
        });
    }

    /**
     * Destroys element
     */
    destroyElement() {
        this.deselectAll();
        this.deselectSelection();

        return this.simplePromise(() => {
        });
    }

    refreshSelect() {
        this._refreshToSelect().then(() => {
            this.refresh_to_select      = true;
            this.selectable_elements    = this.model.element.options.filter(this.toSelectListingMatcher.bind(this));
            this.to_select_filter_count = this.selectable_elements.length;
        });
    }

    refreshDeselect() {
        this._refreshToDeselect().then(() => {
            this.refresh_to_deselect      = true;
            this.deselectable_elements    = this.selected_options.filter(this.toDeselectListingMatcher.bind(this));
            this.to_deselect_filter_count = this.deselectable_elements.length;
        });
    }

    /**
     * Subscribes observers
     */
    subscribeObservers() {
        super.subscribeObservers();

        this.observers.push(
            this.appContainer
                .bindingEngine
                .propertyObserver(this, 'to_select_filter')
                .subscribe((newValue, oldValue) => {
                    this.refreshSelect();
                }),
        );

        this.observers.push(
            this.appContainer
                .bindingEngine
                .propertyObserver(this, 'to_deselect_filter')
                .subscribe((newValue, oldValue) => {
                    this.refreshDeselect();
                }),
        );

        // register special observers if additional filters have been added
        let additional_filters = this.model.element.additional_filters;
        if (additional_filters instanceof Array) {
            for (let index in additional_filters) {
                this.observers.push(
                    this.appContainer
                        .bindingEngine
                        .propertyObserver(additional_filters[index].model, additional_filters[index].model_field)
                        .subscribe((newValue, oldValue) => {
                            this.refreshSelect();
                        }),
                );
            }
        }
    }

    selectAll() {
        this._transferAll(
            this.model.element.options,
            this.selected_options,
            this.toSelectListingMatcher.bind(this),
        );
    }

    selectSelection() {
        this._selectByArray(
            this.model.element.options,
            this.to_select_values,
            this.selected_options,
        );
    }

    deselectAll() {
        this._transferAll(
            this.selected_options,
            this.model.element.options,
            this.toDeselectListingMatcher.bind(this),
        );
    }

    deselectSelection() {
        this._selectByArray(
            this.selected_options,
            this.to_deselect_values,
            this.model.element.options,
        );
    }

    toSelectListingMatcher(element) {
        if (typeof element !== 'object') {
            return true;
        }

        // check for additional filters
        let element_filters    = [];
        let additional_filters = this.model.element.additional_filters;

        if (
            additional_filters instanceof Array
        ) {
            for (let a in additional_filters) {
                if (element[additional_filters[a].remote_source_field] instanceof Array) {
                    element_filters.push(
                        !additional_filters[a].model[additional_filters[a].model_field] ||
                        element[additional_filters[a].remote_source_field].indexOf(additional_filters[a].model[additional_filters[a].model_field]) !== -1,
                    );
                } else {
                    element_filters.push(
                        !additional_filters[a].model[additional_filters[a].model_field] ||
                        element[additional_filters[a].remote_source_field] ==
                        additional_filters[a].model[additional_filters[a].model_field],
                    );
                }
            }
        } else {
            element_filters.push(true); // ☺
        }

        let element_filtered = false;
        element_filters.forEach((element) => {
            element_filtered = element_filtered | element;
        });

        // return element.name.toLowerCase().includes(this.to_select_filter.toLowerCase()) & element_filtered;
        return this.__includes.bind(element.name.toLowerCase())(this.to_select_filter.toLowerCase()) & element_filtered;
    }

    toDeselectListingMatcher(element) {
        if (typeof element !== 'object') {
            return true;
        }
        // return element.name.toLowerCase().includes(this.to_deselect_filter.toLowerCase());
        return this.__includes.bind(element.name.toLowerCase())(this.to_deselect_filter.toLowerCase());
    }

    /**
     * This function exists as a polyfill for the function String.prototype.includes
     * TODO: Remove this function as soon as possible and replace with proper polyfill
     */
    __includes(search, start) {
        'use strict';
        if (typeof start !== 'number') {
            start = 0;
        }

        if (start + search.length > this.length) {
            return false;
        } else {
            return this.indexOf(search, start) !== -1;
        }
    }

    _transferAll(source_array, destination_array, filter_function) {
        let to_transfer = source_array.filter(filter_function);
        destination_array.splice(0, 0, ...to_transfer);

        source_array.splice(0, source_array.length, ...source_array.filter((element) => {
            return typeof to_transfer.find((inner_element) => {
                return inner_element.id == element.id;
            }) === 'undefined';
        }));

        this._afterSelectionEvent();
    }

    _selectByArray(select_options, selector_array, select_destination) {
        for (var a in selector_array) {
            let index = select_options.findIndex((element) => {
                return element.id == selector_array[a];
            });

            if (index !== -1) {
                // remove the element from the options and add it to the
                // selected options
                let option = select_options[index];
                select_options.splice(index, 1);
                select_destination.push(option);
            }
        }

        this._afterSelectionEvent();
    }

    _afterSelectionEvent() {
        this.deselectable_elements.sort(function (a, b) {
            return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
        });
        this.selectable_elements.sort(function (a, b) {
            return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
        });

        this.to_select_filter_count   = this.model.element.options.filter(this.toSelectListingMatcher.bind(this)).length;
        this.to_deselect_filter_count = this.selected_options.filter(this.toDeselectListingMatcher.bind(this)).length;

        if (!this.model.value instanceof Array) {
            this.model.value = [];
        }

        this.model.value.splice(0, this.model.value.length, ...this.selected_options.map((element) => {
            return element.id;
        }));

        this.refreshSelect();
        this.refreshDeselect();
    }

    _refreshToSelect() {
        return new Promise((s, f) => {
            this.refresh_to_select = false;
            s();
        });
    }

    _refreshToDeselect() {
        return new Promise((s, f) => {
            this.refresh_to_deselect = false;
            s();
        });
    }
}
