import { customElement, inject } from 'aurelia-framework';
import { BaseComponent }         from 'resources/elements/aurelia-form/components/base-component';
import { AppContainer }          from 'resources/services/app-container';

@inject(AppContainer)
@customElement('form-divider')
export class FormFieldGroup extends BaseComponent {
    
    /**
     * Constructor
     *
     * @param appContainer
     */
    constructor(appContainer) {
        super(appContainer);
    }
}
