import { bindable, inject } from 'aurelia-framework';
import { BaseComponent }    from 'resources/elements/aurelia-form/components/base-component';
import { AppContainer }     from 'resources/services/app-container';
import 'assets/js/plugins/forms/inputs/duallistbox.min';

@inject(AppContainer)
export class FormDuallistbox extends BaseComponent {

    @bindable customSettings = {};

    /**
     * Constructor
     *
     * @param appContainer
     */
    constructor(appContainer) {
        super(appContainer);

        this.defaultSettings = {
            preserveSelectionOnMove: 'moved',
            moveOnSelect:            false,
            filterTextClear:         this.appContainer.i18n.tr('component.field.show-all'),
            filterPlaceHolder:       this.appContainer.i18n.tr('component.field.filter'),
            moveSelectedLabel:       this.appContainer.i18n.tr('component.field.move-selected'),
            moveAllLabel:            this.appContainer.i18n.tr('component.field.move-all'),
            removeSelectedLabel:     this.appContainer.i18n.tr('component.field.remove-selected'),
            removeAllLabel:          this.appContainer.i18n.tr('component.field.remove-all'),
            infoText:                this.appContainer.i18n.tr('component.field.showing-all') + ' {0}',
            infoTextFiltered:        '<span class="label label-warning">' + this.appContainer.i18n.tr('component.field.filtered') + '</span> {0} ' + this.appContainer.i18n.tr('component.field.of') + ' {1}',
            infoTextEmpty:           this.appContainer.i18n.tr('component.field.empty-list'),
        };
    }

    /**
     * Returns outer container id
     *
     * @returns {*}
     */
    get outerContainerId() {
        return (this.model.element.id || this.model.element.key) + '_outer_container';
    }

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        this.model          = model;
        this.modelElementId = this.model.element.id || this.model.element.key;

        this.customSettings = this.model.element.settings ? this.model.element.settings : {};

        if (typeof this.model.element.attributes === 'undefined' || this.model.element.attributes === null) {
            this.model.element.attributes = {};
        }

        if (typeof this.model.element.attributes.disabled === 'undefined' || this.model.element.attributes.disabled === null) {
            this.model.element.attributes.disabled = false;
        }

        if (typeof this.model.element.options !== Array) {
            this.model.element.options = [];
        }

        // this instance in order to be possible to access it from outside
        this.model.element.instance = this;

        return this.fetchData();
    }

    /**
     * Fetches data from remote source
     *
     * @returns {*}
     * @returns {*}
     */
    fetchData() {
        let parameters = {};

        this.model.element.options.splice(0, this.model.element.options.length);

        if (this.model.element.remoteSourceParameters instanceof Function) {
            parameters = this.model.element.remoteSourceParameters();

            if (!parameters) {
                return Promise.resolve([]);
            }
        }

        return this.model.element.remoteSource(parameters)
            .then((response) => {
                if (this.model.element.processResults instanceof Function) {
                    for (let i = 0; i < response.length; i++) {
                        this.model.element.options.push(this.model.element.processResults(response[i]));
                    }
                } else {
                    this.model.element.options.splice(0, this.model.element.options.length, ...response);
                }
                return response;
            });
    }

    /**
     * Creates element
     */
    createElement() {
        return this.simplePromise(() => {
            let htmlElement = $('#' + this.modelElementId);

            let settings = this.handleSettings();

            $(htmlElement)
                .bootstrapDualListbox(settings)
                .on('change', function (event) {
                    if (event.originalEvent) {
                        return;
                    }

                    let notice = new CustomEvent('change', {
                        bubbles: false,
                    });

                    $(htmlElement)[0].dispatchEvent(notice);
                });

            // $('#' + this.modelElementId + ' option').prop('selected', false);
            $(htmlElement).bootstrapDualListbox('refresh', true);

            this.handleDisabledAttribute();
        });
    }

    /**
     * Destroys element
     */
    destroyElement() {
        return this.simplePromise(() => {
            $('#' + this.modelElementId).bootstrapDualListbox('refresh', true);
        });
    }

    /**
     * Disables element
     */
    handleDisabledAttribute() {
        return this.simplePromise(() => {
            let disabled = this.model.element.attributes.disabled === true;

            $('#' + this.outerContainerId).find('.bootstrap-duallistbox-container').find('*').prop('disabled', disabled);
        });
    }

    /**
     * Returns selected attribute
     *
     * @param option
     *
     * @returns {string} 'select' if option === model, otherwise returns ''
     */
    selectedAttribute(option) {
        if (this.model.value === null || typeof this.model.value === 'undefined') {
            return '';
        }

        return this.model.value.indexOf(option) > -1 ? 'selected' : '';
    }

    redoTranslations() {
        this.defaultSettings.filterTextClear     = this.appContainer.i18n.tr('component.field.show-all');
        this.defaultSettings.filterPlaceHolder   = this.appContainer.i18n.tr('component.field.filter');
        this.defaultSettings.moveSelectedLabel   = this.appContainer.i18n.tr('component.field.move-selected');
        this.defaultSettings.moveAllLabel        = this.appContainer.i18n.tr('component.field.move-all');
        this.defaultSettings.removeSelectedLabel = this.appContainer.i18n.tr('component.field.remove-selected');
        this.defaultSettings.removeAllLabel      = this.appContainer.i18n.tr('component.field.remove-all');
        this.defaultSettings.infoText            = this.appContainer.i18n.tr('component.field.showing-all') + ' {0}';
        this.defaultSettings.infoTextFiltered    = '<span class="label label-warning">' + this.appContainer.i18n.tr('component.field.filtered') + '</span> {0} ' + this.appContainer.i18n.tr('component.field.of') + ' {1}';
        this.defaultSettings.infoTextEmpty       = this.appContainer.i18n.tr('component.field.empty-list');

        let modelElement = $('#' + this.modelElementId);

        $(modelElement).bootstrapDualListbox('setFilterTextClear', this.defaultSettings.filterTextClear);
        $(modelElement).bootstrapDualListbox('setFilterPlaceHolder', this.defaultSettings.filterPlaceHolder);
        $(modelElement).bootstrapDualListbox('setMoveSelectedLabel', this.defaultSettings.moveSelectedLabel);
        $(modelElement).bootstrapDualListbox('setMoveAllLabel', this.defaultSettings.moveAllLabel);
        $(modelElement).bootstrapDualListbox('setRemoveSelectedLabel', this.defaultSettings.removeSelectedLabel);
        $(modelElement).bootstrapDualListbox('setRemoveAllLabel', this.defaultSettings.removeAllLabel);
        $(modelElement).bootstrapDualListbox('setInfoText', this.defaultSettings.infoText);
        $(modelElement).bootstrapDualListbox('setInfoTextFiltered', this.defaultSettings.infoTextFiltered);
        $(modelElement).bootstrapDualListbox('infoTextEmpty', this.defaultSettings.infoTextEmpty);
    }

    /**
     * Subscribes observers
     */
    subscribeObservers() {
        super.subscribeObservers();

        if (this.model.value instanceof Array || this.model.value instanceof Map || this.model.value instanceof Set) {
            this.observers.push(
                this.appContainer
                    .bindingEngine
                    .collectionObserver(this.model.value)
                    .subscribe((splices) => this.destroyElement().then(() => this.createElement())),
                this.appContainer
                    .bindingEngine
                    .collectionObserver(this.model.element.options)
                    .subscribe((splices) => this.destroyElement().then(() => this.createElement())),
            );
        }

        this.observers.push(
            this.appContainer
                .bindingEngine
                .propertyObserver(this.model.element.attributes, 'disabled')
                .subscribe((nv, ov) => this.handleDisabledAttribute()),
        );
    }

    /**
     * Subscribes event listeners
     */
    subscribeEventListeners() {
        super.subscribeEventListeners();

        this.eventListeners.push(
            this.appContainer.eventAggregator.subscribe('locale-changed', () => {
                this.fetchData()
                    .then((response) => {
                        this.redoTranslations();
                        $('#' + this.modelElementId + ' option').prop('selected', false);
                        $('#' + this.modelElementId).bootstrapDualListbox('refresh', true);
                    });
            }),
        );

        // subscribes `form-element-options-updated` event
        this.eventListeners.push(
            this.appContainer.eventAggregator.subscribe('form-element-options-updated', (elementId) => {
                if (!elementId || elementId === this.modelElementId) {
                    // destroy & recreate element
                    this.destroyElement().then(() => setTimeout(() => this.createElement(), 0));
                }
            }),
        );
    }

    /**
     * Refreshes labels
     */
    refreshLabels() {
        this.fetchData()
            .then((response) => {
                this.redoTranslations();
                $('#' + this.modelElementId + ' option').prop('selected', false);
                $('#' + this.modelElementId).bootstrapDualListbox('refresh', true);
            });
    }

    /**
     * Handles settings
     */
    handleSettings() {
        return $.extend({}, this.defaultSettings, this.customSettings);
    }

}
