import { customElement, inject } from 'aurelia-framework';
import { BaseComponent }         from 'resources/elements/aurelia-form/components/base-component';
import { FormHelperService }     from 'resources/elements/aurelia-form/components/form-helper-service';
import { AppContainer }          from 'resources/services/app-container';

@inject(AppContainer, FormHelperService)
@customElement('form-input')
export class FormInput extends BaseComponent {

    /**
     * Constructor
     *
     * @param appContainer
     * @param formHelperService
     */
    constructor(appContainer, formHelperService) {
        super(appContainer);

        this.formHelperService = formHelperService;

        this.acceptableTypes = ['text', 'number', 'email', 'password'];
    }

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        if (this.acceptableTypes.indexOf(model.element.type) === -1) {
            model.element.type = 'text';
        }

        model.element.inputGroup = this.formHelperService.inputGroup(model.element);

        super.activate(model);
    }
}
