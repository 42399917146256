import { customElement, inject } from 'aurelia-framework';
import { BaseComponent }         from 'resources/elements/aurelia-form/components/base-component';
import { AppContainer }          from 'resources/services/app-container';

@inject(AppContainer)
@customElement('form-pickadate')
export class FormPickadate extends BaseComponent {

    /**
     * Constructor
     *
     * @param appContainer
     */
    constructor(appContainer) {
        super(appContainer);
    }

    /**
     * Creates element
     */
    createElement() {
        return this.simplePromise(() => {

            let htmlElement = $('#' + this.modelElementId);

            $(htmlElement).pickadate({
                format:   'yyyy-mm-dd',
                editable: false,
            }).on('change', function (event) {
                if (event.originalEvent) {
                    return;
                }

                let notice = new CustomEvent('change', {
                    bubbles: false,
                });

                $(htmlElement)[0].dispatchEvent(notice);
            });

            // If there is already an input group defined, move the dom elements created by pickadate to it
            let parents = $(htmlElement).parents('.input-group');

            if (parents.length == 2) {
                let top = $(parents[0]).detach();

                $(parents[1]).prepend($(top).children());
            }

        });
    }

    /**
     * Destroys element
     */
    destroyElement() {
        return this.simplePromise(() => {

            $('#' + this.modelElementId).stop();

        });
    }
}
