import { customElement, inject } from 'aurelia-framework';
import autosize                  from 'autosize';
import { BaseComponent }         from 'resources/elements/aurelia-form/components/base-component';
import { FormHelperService }     from 'resources/elements/aurelia-form/components/form-helper-service';
import { AppContainer }          from 'resources/services/app-container';

@inject(AppContainer, FormHelperService)
@customElement('form-textarea')
export class FormTextarea extends BaseComponent {

    /**
     * Constructor
     *
     * @param appContainer
     * @param formHelperService
     */
    constructor(appContainer, formHelperService) {
        super(appContainer);

        this.formHelperService = formHelperService;
    }

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        model.element.inputGroup = this.formHelperService.inputGroup(model.element);

        super.activate(model);
    }

    /**
     * Handles attached event
     */
    attached() {
        super.attached();

        let self = this;

        $('#' + this.modelElementId).closest('.tab-pane').bind('cssClassChanged', function () {
            if ($(this).hasClass('active')) {
                autosize.update($('#' + self.modelElementId));
            }
        });
    }

    /**
     * Creates element
     */
    createElement() {
        return this.simplePromise(() => {
            let element = $('#' + this.modelElementId);

            setTimeout(() => autosize(element), 0);
        });
    }

    /**
     * Destroys element
     */
    destroyElement() {
        return this.simplePromise(() => {
            let element = $('#' + this.modelElementId);

            setTimeout(() => autosize.destroy(element), 0);
        });
    }
}
