import { customElement, inject } from 'aurelia-framework';
import Dropzone                  from 'dropzone';
import { BaseComponent }         from 'resources/elements/aurelia-form/components/base-component';
import { AppContainer }          from 'resources/services/app-container';

@inject(AppContainer)
@customElement('form-file-dropzone')
export class FormFile extends BaseComponent {

    dropzoneElementId = null;
    dropzoneElement   = null;

    /**
     * Constructor
     *
     * @param appContainer
     */
    constructor(appContainer) {
        super(appContainer);

        let randomNumber = Math.floor(Math.random() * (65536 - 1 + 1) + 1);

        this.dropzoneElementId = 'dropzone_container_' + randomNumber;
    }

    /**
     * Creates element
     */
    createElement() {
        return this.simplePromise(() => {
            let self     = this;
            let token    = self.appContainer.localStorage.authToken();
            let selector = '#' + this.dropzoneElementId;

            this.dropzoneElement = new Dropzone(selector, {
                maxFiles:           1,
                maxFilesize:        40, // MB
                addRemoveLinks:     true,
                dictDefaultMessage: self.appContainer.i18n.tr('text.dropzone.dict-default-message'),
                dictRemoveFile:     self.appContainer.i18n.tr('text.dropzone.dict-remove-file'),
                dictCancelUpload:   self.appContainer.i18n.tr('text.dropzone.dict-cancel-upload'),
                acceptedFiles:      self.model.element.allowedMimeTypes,
                init:               function () {
                    this.on('addedfile', function (file) {
                        if (this.fileTracker) {
                            this.removeFile(this.fileTracker);
                        }

                        this.fileTracker = file;
                        self.model.value = file;

                        // If no thumbnail is available, use a default one equivalent to the file type (image, pdf, etc) or a generic one
                        self.setCustomThumbnail(this, file);
                    });

                    this.on('removedfile', function (file) {
                        self.model.value = null;
                    });
                },
                url:                self.model.element.url,
                headers:            {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization':    `Bearer ${token}`,
                },
                success:            (file, response) => {
                    if (typeof response === 'object' && response.status === true) {
                        self.model.value = self.model.element.returnModel ? response.file : response.file.id;
                    } else if (typeof response === 'string') {
                        self.model.value = response;
                    }
                },
                error:              (file, response) => {
                    if (file.previewElement) {
                        file.previewElement.classList.add('dz-error');
                        if (typeof response !== 'string' && response.errors) {
                            response = response.errors.replace(/<\/?li>/g, '').replace(/<\/?ul>/g, '');
                        }
                        for (let node of file.previewElement.querySelectorAll(
                            '[data-dz-errormessage]',
                        )) {
                            node.textContent = response;
                        }
                    }
                },
            });

            // prepopulate dropzone with file if this is an edit operation
            self.mockFileChanged();

            // force the form group label to be shown
            self.fixLabel();
        });
    }

    /**
     * Subscribes observers
     */
    subscribeObservers() {
        // subscribes model.value property change
        this.observers.push(
            this.appContainer
                .bindingEngine
                .propertyObserver(this.model, 'value')
                .subscribe(this.modelValueChanged.bind(this)),
        );

        this.observers.push(
            this.appContainer
                .bindingEngine
                .propertyObserver(this.model.element, 'mockFile')
                .subscribe(this.mockFileChanged.bind(this)),
        );
    }

    /**
     * Destroys dropzone element
     */
    destroyElement() {
        return this.simplePromise(() => {
            if (this.dropzoneElement) {
                this.dropzoneElement.destroy();
                this.dropzoneElement = null;
            }
        });
    }

    /**
     * Fires on model value change
     */
    modelValueChanged(nv, ov) {
        if (!isNumeric(nv) && !nv) {
            this.removeFiles();
        }
    }

    /**
     * Returns mock file data
     *
     * @return {{size, name: (null|*), type: *}|null}
     */
    getMockFile() {
        const getFileData = (file) => ({
            name: file.display_name,
            size: file.size,
            type: file.mime_type,
        });

        if (this.model.element.mockFile) {
            return getFileData(this.model.element.mockFile);
        }

        if (this.model.completeModel) {
            const completeFile = this.model.completeModel[this.model.element.key + '_complete'];
            if (completeFile) {
                return getFileData(completeFile);
            }
        }

        return null;
    }

    /**
     * Fires on model element edit value change
     */
    mockFileChanged(nv, ov) {
        const mockFile = this.getMockFile();

        if (mockFile) {
            this.dropzoneElement.emit('addedfile', mockFile);
            this.dropzoneElement.emit('complete', mockFile);
            // this.dropzoneElement.emit("thumbnail", mockFile, this.model.element.url);
            // this.dropzoneElement.createThumbnailFromUrl(mockFile, this.model.element.url);
        }
    }

    /**
     * Destroys dropzone element
     */
    removeFiles() {
        if (this.dropzoneElement) {
            this.dropzoneElement.removeAllFiles();
        }
    }

    /**
     * Fix the field's label not being shown
     */
    fixLabel() {
        $('#' + this.dropzoneElementId).parents('.form-group-float').children('.form-group-float-label').addClass('is-visible');
    }

    /**
     * Set custom thumbnail based on file type
     */
    setCustomThumbnail(dropzone, file) {
        let icon;

        switch (file.type) {
            case 'image/jpeg':
                icon = 'fa fa-file-image-o text-primary';
                break;
            case 'image/png':
                icon = 'fa fa-file-image-o text-primary';
                break;
            case 'image/gif':
                icon = 'fa fa-file-image-o text-primary';
                break;
            case 'image/bmp':
                icon = 'fa fa-file-image-o text-primary';
                break;
            case 'application/pdf':
                icon = 'fa fa-file-pdf-o text-danger';
                break;
            case 'application/msword':
                icon = 'fa fa-file-word-o text-primary';
                break;
            case 'application/vnd.ms-excel':
                icon = 'fa fa-file-excel-o text-success';
                break;
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                icon = 'fa fa-file-excel-o text-success';
                break;
            case 'application/vnd.ms-powerpoint':
                icon = 'fa fa-file-powerpoint-o text-danger';
                break;
            case 'application/zip':
                icon = 'fa fa-file-archive-o text-muted';
                break;
            case 'text/html':
                icon = 'fa fa-file-code-o text-info';
                break;
            case 'text/plain':
                icon = 'fa fa-file-text-o text-info';
                break;
            case 'video/quicktime':
                icon = 'fa fa-file-movie-o text-warning';
                break;
            case 'audio/mpeg':
                icon = 'fa fa-file-audio-o text-warning';
                break;
            case 'video/mp4':
                icon = 'fa fa-file-movie-o text-warning';
                break;
            case 'audio/mp3':
                icon = 'fa fa-file-audio-o text-warning';
                break;
            default:
                icon = 'fa fa-file-o text-secondary';
        }

        let thumbnailElement       = file.previewElement.querySelector('[data-dz-thumbnail]').parentNode;
        thumbnailElement.innerHTML = `<div class="dropzone-icon"><i class="${icon}"></i></div>`;

        // Add filename outside the dz-details div
        let filenameElement       = document.createElement('div');
        filenameElement.className = 'dz-visible-filename';
        filenameElement.innerHTML = `<div class="dz-filename"><span data-dz-name="">${file.name}</span></div>`;
        file.previewElement.insertBefore(filenameElement, file.previewElement.querySelector('.dz-progress'));
    }
}
