import { inject, useView }              from 'aurelia-framework';
import { UserEntityTeamLotsRepository } from 'modules/administration/users/user-entity-team-lot/services/repository';
import { FormHelperService }            from 'resources/elements/aurelia-form/components/form-helper-service';
import { FormMultiselectNative }        from 'resources/elements/aurelia-form/components/form-multiselect-native';
import { AppContainer }                 from 'resources/services/app-container';

@inject(AppContainer, FormHelperService, UserEntityTeamLotsRepository)
@useView('./form-multiselect-native.html')
export class FormUserEntityTeamLotMultiselect extends FormMultiselectNative {

    /**
     * Constructor
     *
     * @param appContainer
     * @param formHelperService
     * @param repository
     */
    constructor(appContainer, formHelperService, repository) {
        super(appContainer, formHelperService);

        this.repository = repository;
    }

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        model.element.remoteSource = () => this.repository.active();

        return super.activate(model);
    }

}
